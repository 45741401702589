import { clone, forEach, isString, defaultTo } from 'lodash';

// mapper
const filtersMapper = (filters) => {
  const filtersCopy = clone(filters);

  forEach(filtersCopy, (value, key) => {
    if (value) {
      filtersCopy[key] = value.map((filter) => (isString(filter) ? filter : {
        value: filter.id, name: defaultTo(filter.name, 'Unnamed') }));
    }
  });

  return filtersCopy;
};

export default filtersMapper;
