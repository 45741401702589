<template>
  <div class="pt-4">
    <v-divider></v-divider>
    <div v-for="(info, index) in gameSpecificGeneralInfo" :key="index" class="pl-3 mt-2">
      <v-row>
        <p class="subtitle-1 pt-6 mb-0 subtitle--text">{{ info.title }}</p>
      </v-row>
      <v-row class="table-wrapper">
        <v-col>
          <v-data-table
            class="betTable"
            :hide-default-footer="true"
            disable-pagination
            :headers="gameSpecificHeaders"
            :items="formattedBets"
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-icon :color="item.statusColor">{{ item.status }}</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <p class="subtitle-1 pt-6 mb-0 subtitle--text">{{ getTranslation('totals')}}</p>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="4"
          v-for="(item, index) in info.data"
          :key="index"
          class="ma-0 pa-0"
        >
          <div class="ticketDataWrapper">
            <p class="label body-2 mt-2">
              {{ item.label }}
            </p>
            <p :class="['value', 'caption', { 'subtitle--text' : isDarkMode }]">
              {{ getFormattedInfo(selectedTicket, item.value) }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { get } from 'lodash';
import { getStatusIcon, getStatusColor, roundDecimals, nilSentry } from '@/utility';
import { productTypes } from '@/utility/types';

export default {
  name: 'TicketDetails',
  data() {
    const { translations } = this.$store.getters;

    return {
      generalInfo: [
        {
          title: '',
          value: 'info',
          data: [
            {
              label: translations.stake,
              value: 'totals.stake.real.value',
            },
            {
              label: translations.payout,
              value: 'totals.payout.real.value',
            },
            {
              label: translations.status,
              value: 'status',
            },
            {
              label: translations.jackpot,
              value: 'totals.jackpot.real.value',
            },
            {
              label: translations.paymentTax,
              value: 'paymentTax',
            },
            {
              label: translations.payoutTax,
              value: 'payoutTax',
            },
          ],
        },
      ],
      betHeaders: [
        {
          text: translations.round,
          align: 'start',
          sortable: false,
          value: 'round',
        },
        {
          text: translations.eventId,
          align: 'start',
          sortable: false,
          value: 'eventId',
        },
        {
          text: translations.type,
          align: 'start',
          sortable: false,
          value: 'type',
        },
        {
          text: translations.pick,
          align: 'start',
          sortable: false,
          value: 'pick',
        },
        {
          text: translations.luckyMultiplier,
          align: 'start',
          sortable: false,
          value: 'luckyMultiplier',
        },
        {
          text: translations.odds,
          align: 'start',
          sortable: false,
          value: 'odds',
        },
        {
          text: translations.stake,
          align: 'end',
          sortable: false,
          value: 'stake',
        },
        {
          text: translations.payoutTax,
          align: 'end',
          sortable: false,
          value: 'payoutTax',
        },
        {
          text: translations.paymentTax,
          align: 'end',
          sortable: false,
          value: 'paymentTax',
        },
        {
          text: translations.winnings,
          align: 'end',
          sortable: false,
          value: 'winnings',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'status',
        },
      ],
    };
  },
  methods: {
    localiseAndRoundDecimals(number) {
      return roundDecimals(number, this.locale);
    },
    formatBet(bet) {
      // Each bet has a reference to a selection. The selection contains more info
      // on the corresponding bet, like the odds, the type, etc.
      const { selections } = this.selectedTicket;
      const { selectionIndex } = bet.selectionRefs[0];
      const selection = selections[selectionIndex];
      return {
        ...bet,
        round: selection.eventDisplayId,
        type: this.getTranslation(selection.typeName || selection.marketName),
        eventId: selection.eventId,
        pick: this.isProductCrashCash ? this.localiseAndRoundDecimals(selection.outcomeId) : selection.outcomeName,
        result: this.localiseAndRoundDecimals(selection.result),
        odds: this.localiseAndRoundDecimals(selection.odds),
        stake: this.localiseAndRoundDecimals(bet.stake.real.value),
        winnings: this.localiseAndRoundDecimals(bet.winnings.real.value),
        status: getStatusIcon(bet.status),
        statusColor: getStatusColor(bet.status),
        paymentTax: nilSentry(bet.taxes.find(({ type }) => type === 'PAYIN')?.value,
          this.localiseAndRoundDecimals) || '-',
        payoutTax: nilSentry(bet.taxes.find(({ type }) => type === 'PAYOUT')?.value,
          this.localiseAndRoundDecimals) || '-',
        // This is only temporary, in the future we will have a bets.winings.bonuses.luckyMultiplier for each bet
        luckyMultiplier: selection.additionalDetails.luckyMultiplier || '-',
      };
    },
    getFormattedInfo(ticket, path) {
      return get(ticket, path) || '-';
    },
  },
  computed: {
    ...mapGetters(['selectedTicket', 'translations', 'getTranslation', 'locale']),
    isProductCrashCash() {
      return [productTypes.CrashCash, productTypes['cash-crash']].includes(this.selectedTicket.productName);
    },
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
    formattedBets() {
      const { bets } = this.selectedTicket;
      return [...bets.map(this.formatBet)];
    },
    gameSpecificHeaders() {
      const headers = [...this.betHeaders];
      if (this.isProductCrashCash) {
        headers.splice(3, 0, {
          text: this.translations.result,
          align: 'start',
          sortable: false,
          value: 'result',
        });
      }
      return headers;
    },
    gameSpecificGeneralInfo() {
      const generalInfo = [...this.generalInfo];
      const noJackpotOnProduct = this.isProductCrashCash;
      if (noJackpotOnProduct) {
        generalInfo[0].data = generalInfo[0].data.filter(({ label }) => label !== this.translations.jackpot);
      }
      return generalInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.betTable {
  ::v-deep .v-data-table__wrapper {
    border-bottom: thin solid hsla(0, 0%, 100%, 0.12);
  }
}

.table-wrapper {
  max-height: 500px;
  overflow: auto;
}

.ticketDataWrapper {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 4px;
  margin: 12px 12px 0 0;

  .label,
  .value {
    margin: 3px 10px;
  }
}
</style>
