import { upperFirst, merge, clone, isNil } from 'lodash';

export default {
  activeFilters: (state, {
    platformName,
    currentPage,
  }) => state.activeFilters[currentPage][platformName],
  allFilters: (state, getters) => merge(clone(getters.selectedFiltersPerTenant), getters.dateRange),
  allFiltersPerTenant: (state) => state.allFiltersPerTenant,
  auth: (state) => state.auth,
  info: (state) => state.info,
  config: (state) => state.config,
  currentPage: (state) => state.currentPage,
  currentPageHeaders: (state, { currentPage }) => state.headers[currentPage],
  dateRange: (state) => state.dateRange,
  detailsModalConfig: (state) => state.detailsModalConfig,
  detailsModalToggler: (state) => state.toggleDetailsModal,
  downloadFile: (state) => state.downloadFile,
  footerProps: (state) => ({
    'items-per-page-options': state.rowsCount,
  }),
  /**
   *  True means that the filters network rq failed.
   *  Even when the req fails, we still assign search to ''
   * @returns {boolean}
   */
  hasFiltersReqFailed: (state, getters) => Object.keys(getters.allFiltersPerTenant).length === 1,
  locale: (state) => state.config.locale,
  collectedFilters: (state, { selectedFiltersPerTenant, dateRange }) => merge(clone(selectedFiltersPerTenant), dateRange),
  getAllForFilterPerTenant: (state) => (filter) => state.allFiltersPerTenant[filter],
  getSelectedFilterPerTenant: (state, getters) => (filter) => getters
    .selectedFiltersPerTenant[filter],
  getTranslation: (state, getters) => (key) => getters.translations[key] || key,
  isMobile: (state) => state.isMobile,
  isSeven: (state, getters) => getters.platformName === 'seven',
  isMobileAndStandalone: (state, { isStandalone, isMobile }) => isStandalone && isMobile,
  isInfoVisible: (state) => !isNil(state.info),
  isStandalone: (state, getters) => getters.platformName === 'standalone',
  keycloak: (state) => state.keycloak,
  layout: (state, getters) => upperFirst(getters.platformName),
  loading: (state) => state.loading,
  navigation: (state) => state.navigation,
  platformName: (state) => state.config.platformName,
  selectedFilters: (state) => state.selectedFilters,
  selectedFiltersPerTenant: (state) => state.selectedFiltersPerTenant,
  selectedTenants: (state) => state.selectedFiltersPerTenant.tenants,
  selectedTicket: (state) => state.selectedTicket,
  sortableFields: (state, { currentPage }) => state.sortableFields[currentPage],
  tenantId: (state, getters) => getters.config.tenantId,
  translations: (state) => state.translations,
  isDarkMode: (state) => state.theme === 'dark',
};
