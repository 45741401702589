<template>
  <div>
    <template v-if="isStandalone">
      <div class="pageTitleWrapper" v-if="!isMobile">
        <router-link class="text--text" :to="{ path: $route.matched[0].path }">
          <p class="body-1 pageTitle">
            {{ $route.matched[0].name }}
          </p>
        </router-link>
      </div>
      <v-divider></v-divider>
    </template>
    <EventsGrid />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import EventsGrid from '@/components/EventsGrid';

export default {
  name: 'Events',
  components: {
    EventsGrid,
  },
  computed: {
    ...mapGetters(['isStandalone', 'isMobile']),
  },
  methods: {
    ...mapActions(['setCurrentPage']),
  },
  mounted() {
    this.setCurrentPage('events');
  },
};
</script>

<style lang="scss" scoped>
.pageTitleWrapper {
  margin: 8px 0 24px 0;

  .pageTitle {
    text-transform: capitalize;
    display: inline;
  }
}

a {
  text-decoration: none;
}

.router-link-active {
  opacity: 0.5;
}

.router-link-exact-active {
  opacity: 1;
}
</style>
