<template>
  <div>
    <div class="filterContainer">
      <div
        v-if="getIsFilterActive(filterTypes.DATE_TIME_PICKER)"
        class="filterItem"
        :class="{ datePicker: isStandalone }"
      >
        <FilterSelectDateTime
          :maxRange="0"
          :dateFromSelected="$route.query.dateFrom"
          :dateToSelected="$route.query.dateTo"
          :label="translations.date"
          @updateDateRange="clearAndRefreshFilters"
        />
      </div>
      <div v-for="filterData in activeFiltersForDisplay" class="filterItem" :key="filterData.type">
        <FilterMultiSelect
          :type="filterData.type"
          :labelKey="filterData.labelKey"
          :listName="filterData.listName"
          :contentProp="filterData.contentProp"
          :multiple="filterData.multiple"
          :item-key="filterData.itemKey"
          :disabled="isDisabled(filterData.listName)"
          v-on="filterData.eventHandlers"
        >
          <template v-slot:autocomplete-item="{ item }">
            {{ item }}
          </template>
        </FilterMultiSelect>
      </div>
      <div
        :class="['filterItem', { eventsSearchFilter: isEventsPage }]"
        v-if="getIsFilterActive(filterTypes.SEARCH_FILTER)"
      >
        <v-text-field
          v-model="search"
          outlined
          dense
          clearable
          hide-details
          append-icon="mdi-magnify"
          :label="translations.search"
          @keyup.enter="setSearchCollectAndEmitFilters(search)"
          @click:clear="setSearchCollectAndEmitFilters('')"
          @blur="
            setSelectedFiltersPerTenantValues({
              search,
            })
          "
        >
        </v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { endOfDay, formatISO, startOfDay } from 'date-fns';
import { clone, merge, forEach } from 'lodash';
import filterTypes from '@/utility/types/filterTypes';
import {
  formatQueryParams,
  fromParamsObjToStateObj,
  filtersMapper,
  splitAfterFirst,
} from '@/utility';
import FilterSelectDateTime from '@/components/Filters/FilterSelectDateTime';
import FilterMultiSelect from './FilterMultiSelect';

export default {
  name: 'filterHandler',
  props: {
    payload: {
      type: Object,
    },
    inDialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FilterSelectDateTime,
    FilterMultiSelect,
  },
  data() {
    return {
      search: '',
      filtersTemplateData: [
        {
          type: filterTypes.COMPANY_FILTER,
          listName: 'tenants',
          labelKey: 'company',
          contentProp: 'name',
          itemKey: 'value',
          multiple: true,
          eventHandlers: {
            'update-tenants': this.clearAndRefreshFilters,
          },
        },
        {
          type: filterTypes.CHANNEL_FILTER,
          listName: 'channels',
          labelKey: 'channel',
        },
        {
          type: filterTypes.PRODUCT_FILTER,
          listName: 'products',
          labelKey: 'product',
          multiple: true,
        },
        {
          type: filterTypes.BETSHOP_FILTER,
          listName: 'betshops',
          labelKey: 'betshop',
          contentProp: 'name',
          multiple: true,
        },
        {
          type: filterTypes.OPERATOR_FILTER,
          listName: 'operators',
          labelKey: 'operator',
          contentProp: 'name',
        },
        {
          type: filterTypes.PLAYER_FILTER,
          listName: 'players',
          labelKey: 'player',
          contentProp: 'name',
        },
        {
          type: filterTypes.STATUS_FILTER,
          listName: 'statuses',
          labelKey: 'status',
        },
      ],
      filters: clone(this.payload),
      filterTypes,
    };
  },
  methods: {
    ...mapActions([
      'getData',
      'setDateRange',
      'clearAllFilters',
      'setAllFiltersPerTenant',
      'clearSelectedFiltersPerTenant',
      'setSelectedFiltersPerTenantValues',
    ]),
    handleResetFilters() {
      if (this.isSeven) {
        this.clearSelectedFiltersPerTenant();
      } else {
        this.clearAllFilters();
      }
      this.collectAndEmitAllFilterAsCustomEvent('setFilters');
    },
    initSearchValue() {
      this.search = this.getSelectedFilterPerTenant('search');
    },
    setSearchCollectAndEmitFilters(search) {
      this.setSelectedFiltersPerTenantValues({
        search: search.trim(),
      });
      this.collectAndEmitAllFilterAsCustomEvent('setFilters');
    },
    // GG - Gravity Gateway
    initFiltersFromGG() {
      const { tenantId, productName } = this.config;

      this.setSelectedFiltersPerTenantValues({
        products: [productName],
        tenants: [tenantId],
      });
    },
    clearAndRefreshFilters() {
      this.clearSelectedFiltersPerTenant();

      this.refreshFilters(this.selectedTenants);

      this.collectAndEmitAllFilterAsCustomEvent('setFilters');
    },
    /**
     * Checks if a filter should be disabled
     * (Betshop and/or Operator filter cannot be active at the same time as the Player filter)
     */
    isDisabled(filterName) {
      const alwaysEnabled = ['tenants', 'products', 'statuses', 'channels'];

      if (alwaysEnabled.includes(filterName)) return false;
      const validators = {
        players: () => !!(this.selectedFilter('betshops')?.length || this.selectedFilter('operators')?.length),
        betshops: () => !!this.selectedFilter('players')?.length,
        operators: () => !!this.selectedFilter('players')?.length,
      };
      const isThereFilters = this.allFiltersPerTenant[filterName]?.length;
      return validators[filterName]() || !isThereFilters;
    },
    getIsFilterActive(filter) {
      return this.activeFilters.includes(filter);
    },
    /**
     * The initial call for filters only retrieves the tenants array
     */
    async getInitialFilters() {
      const products = this.selectedFilter('products');
      if (this.isSeven) {
        return this.getAndTransformFilters({
          route: `filters?${formatQueryParams({
            tenants: [{ value: this.config.tenantId }],
            dateFrom: this.dateRange.dateFrom,
            dateTo: this.dateRange.dateTo,
            products,
          })}`,
        });
      }
      return this.getAndTransformFilters({
        route: `filters?${formatQueryParams({
          ...this.$route.query,
          sortBy: '',
          sortDesc: '',
          products,
          tenants: this.getTenantIdsFromKeycloak().map((id) => ({ value: id })),
        })}`,
      });
    },
    initLocalStateOfFiltersFromQueryParams() {
      const { query } = this.$route;
      // Init selections from the query params
      const stateModel = fromParamsObjToStateObj(query, this.allFiltersPerTenant);

      forEach(stateModel, (filterValues, filterName) => {
        this.setSelectedFiltersPerTenantValues({ [filterName]: filterValues });
      });
    },
    async getAndStoreInitialFilters() {
      const filters = await this.getInitialFilters();

      this.setExpandedFiltersPerTenant(filters);
    },
    /**
     * Called when either the date or the selected tentantId changes
     * Also called upon app initialisation when in Seven mode.
     * This method refreshes the local state of filters. No network
     * requests are made.
     */
    async refreshFilters(tenants) {
      const allFilters = await this.getAndTransformFilters({
        route: `filters?${formatQueryParams({
          tenants,
          dateFrom: this.dateRange.dateFrom,
          dateTo: this.dateRange.dateTo,
          products: this.selectedFilter('products'),
        })}`,
      });

      this.setExpandedFiltersPerTenant(allFilters);
    },
    setInitialDateRange() {
      let dateFrom;
      let dateTo;

      if (this.isSeven) {
        const now = new Date();
        dateFrom = formatISO(startOfDay(now));
        dateTo = formatISO(endOfDay(now));
      } else {
        const dateFromParam = this.$route?.query.dateFrom;
        const dateToParam = this.$route?.query.dateTo;

        if (dateFromParam && dateToParam) {
          dateFrom = formatISO(startOfDay(new Date(dateFromParam)));
          dateTo = formatISO(new Date(dateToParam));
        }
      }

      this.setDateRange({ dateFrom, dateTo });
    },
    /**
     * Expanding the filters object with the search
     * property, as multiple modules' logic depends on
     * its existance, and we do not receive it from our server
     * @param {*} filters
     */
    setExpandedFiltersPerTenant(filters) {
      const filtersClone = clone(filters);
      filtersClone.search = '';

      this.setAllFiltersPerTenant(filtersClone);
    },
    collectAllFilters() {
      return merge(clone(this.selectedFiltersPerTenant), this.dateRange);
    },
    async getAndTransformFilters(options) {
      return filtersMapper(await this.getData(options));
    },
    collectAndEmitAllFilterAsCustomEvent(eventName) {
      this.$emit(eventName, this.collectAllFilters());
    },
    getTenantIdsFromKeycloak() {
      return this.keycloak.idTokenParsed.tenants
        .filter((tenantString) => tenantString.indexOf('GamesTenants'))
        .map((tenantString) => splitAfterFirst(tenantString, '_'));
    },
    initTenantIdsFromKeycloak() {
      this.setSelectedFiltersPerTenantValues({
        tenants: [...this.getTenantIdsFromKeycloak()],
      });
    },
    async initEverything() {
      this.clearAllFilters();
      this.setInitialDateRange();
      if (this.isSeven) {
        this.initFiltersFromGG();
      } else {
        this.initTenantIdsFromKeycloak();
      }
      await this.getAndStoreInitialFilters();
      this.initLocalStateOfFiltersFromQueryParams();
    },
  },
  computed: {
    ...mapGetters([
      'isSeven',
      'dateRange',
      'tenantId',
      'config',
      'keycloak',
      'currentPage',
      'translations',
      'isStandalone',
      'activeFilters',
      'getTranslation',
      'selectedFilters',
      'selectedTenants',
      'allFiltersPerTenant',
      'isMobileAndStandalone',
      'getSelectedFilterPerTenant',
      'selectedFiltersPerTenant',
    ]),
    isEventsPage() {
      return this.currentPage === 'events';
    },
    showResetFiltersBtn() {
      return this.isEventsPage;
    },
    selectedFilter() {
      return (filterName) => this.$store.getters.getSelectedFilterPerTenant(filterName);
    },
    showFilterButton() {
      return this.activeFilters.length > 1;
    },
    /**
     * Filters out only the active filters
     */
    activeFiltersForDisplay() {
      return this.filtersTemplateData.filter(({ type }) => this.activeFilters.includes(type));
    },
  },
  async created() {
    this.initSearchValue();
    if (!this.inDialog) {
      await this.initEverything();
      this.collectAndEmitAllFilterAsCustomEvent('filtersReady');
    }
  },
};
</script>

<style lang="scss" scoped>
.eventsSearchFilter {
  max-width: fit-content;
  grid-column: end !important;
}

.datePicker {
  max-width: fit-content;
}

.filterContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(55px, 1fr));
  grid-template-rows: auto;
  grid-gap: 16px;
  padding: 16px 0;

  .filterItem {
    grid-column: auto / span 3;
    @media screen and (max-width: 1360px) {
      grid-column: auto / span 4;
    }
  }

  .filterButton {
    align-self: center;
  }

  .expandStakeColumn {
    grid-column: auto / span 5;
    @media screen and (max-width: 1360px) {
      grid-column: auto / span 4;
    }
  }

  .offset {
    @media screen and (max-width: 1360px) {
      display: none !important;
    }
  }
}

.filterActions {
  display: flex;
}
</style>
