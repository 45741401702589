<template>
  <v-app>
    <component :is="layout" />
    <v-snackbar v-model="snackbar" :timeout="timeout" :text="text" :color="color">
      <div class="mx-6 text-center" :class="getLabelPosition">
        {{ snackbarLabel }}
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          class="text-none"
          color="primary"
          v-if="download"
          :href="downloadFile.href"
          :download="downloadFile.fileName"
          text
          v-bind="attrs"
        >
          <LocalizedLabel>download</LocalizedLabel>
        </v-btn>
        <v-divider vertical v-if="isSnackbarPersistent" class="mx-2"></v-divider>
        <v-btn icon v-if="isSnackbarPersistent" class="mx-2" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="activator"
      transition="slide-x-reverse-transition"
      content-class="dialogWrapper"
    >
      <v-card width="100%" height="100%" class="pa-8 overflow-auto"> <DetailsModal v-if="activator" /> </v-card
    ></v-dialog>
    <InfoMessage v-if="isInfoVisible"/>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Standalone from '@/layouts/Standalone';
import Seven from '@/layouts/Seven';
import DetailsModal from '@/components/DetailsModal';
import InfoMessage from '@/components/InfoMessage';

export default {
  name: 'App',
  components: {
    Standalone,
    Seven,
    DetailsModal,
    InfoMessage,
  },
  data() {
    return {
      color: '',
      snackbarLabel: '',
      snackbar: false,
      text: false,
      close: false,
      download: false,
      timeout: 3000,
    };
  },
  methods: {
    ...mapActions(['toggleDetailsModal', 'setMobileDetection']),
    activateSnackbar(value) {
      this.download = value.download;
      this.snackbarLabel = value.fileName;
      this.snackbar = true;
    },
  },
  computed: {
    ...mapGetters([
      'detailsModalToggler',
      'detailsModalConfig',
      'layout',
      'downloadFile',
      'isSeven',
      'isInfoVisible',
    ]),
    getLabelPosition() {
      return this.text ? 'text-center' : '';
    },
    isSnackbarPersistent() {
      return this.close || this.download;
    },
    activator: {
      get() {
        return this.detailsModalToggler;
      },
      set() {
        this.toggleDetailsModal(false);
      },
    },
  },
  async created() {
    await this.setMobileDetection();

    if (this.isSeven) {
      this.$busService.sendMessage({
        action: 'Slave.Loaded',
        data: {},
      }, true);
    }
  },
  watch: {
    downloadFile(newValue) {
      this.timeout = newValue.timeout || -1;
      this.activateSnackbar(newValue);
    },
  },
};
</script>
<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>
