<template>
  <v-card class="menu visibleOnOverlay">
    <v-list class="overflow-y-auto elevation-1 py-0 dropdown">
      <v-list-item v-for="(header, index) in filteredHeaders" :key="index">
        <v-checkbox
          :label="getTranslation(header.text)"
          v-model="header.visible"
          class="my-0 py-0 body-2 shrink"
          hide-details
          dense
          :disabled="header.permanent"
          @change="updateVisibleColumns"
        ></v-checkbox>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="cursor: pointer" @click="resetVisibleColumns">
        <v-icon class="mr-2">mdi-restart</v-icon><span class="text--secondary"> {{getTranslation('reset')}} </span>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { filter } from 'lodash';

export default {
  name: 'tableColumnsSelect',
  props: ['headers'],
  methods: {
    ...mapActions(['setHeaders']),
    resetVisibleColumns() {
      this.headers.forEach((header, index) => {
        this.headers[index].visible = header.default;
      });
      this.updateVisibleColumns();
    },
    updateVisibleColumns() {
      // Only save needed props
      const mappedHeadersForLS = this.headers.map(({ visible, value, text }) => ({ visible, value, text }));
      localStorage.setItem(this.$route.name, JSON.stringify(mappedHeadersForLS));
      this.setHeaders({ page: this.currentPage, headers: this.headers });
    },
  },
  computed: {
    ...mapGetters(['getTranslation', 'currentPage']),
    filteredHeaders() {
      return filter(this.headers, 'text');
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: inline-block;
  width: 4px;
  background-color: var(--v-background-base) !important;
  border-radius: 4px !important;
}

::-webkit-scrollbar-thumb {
  background-color: var(--v-disabled-base) !important;
  border-radius: 4px !important;
}

.menu {
  position: relative;

  .dropdown {
    position: absolute;
    right: 0;
    top: 47px;
    max-height: 350px;
  }
}
</style>
