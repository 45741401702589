import axios from 'axios';
import store from '../store';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL,
  timeout: 10000,
});

http.interceptors.request.use((config) => {
  const { token } = store.getters.auth;
  const requestBody = {
    ...config,
    headers: {
      ...config.headers,
      authorization: `Bearer ${token}`,
    },
  };

  return requestBody;
});

export default http;
