import { render, staticRenderFns } from "./TableColumnsSelect.vue?vue&type=template&id=70243178&scoped=true&"
import script from "./TableColumnsSelect.vue?vue&type=script&lang=js&"
export * from "./TableColumnsSelect.vue?vue&type=script&lang=js&"
import style0 from "./TableColumnsSelect.vue?vue&type=style&index=0&id=70243178&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70243178",
  null
  
)

export default component.exports