<template>
  <div>
    <!-- FILTERS -->
    <div class="filterHandlerWrapper" :class="{ mobile: isMobile }">
      <FilterHandler
        v-if="!isMobileAndStandalone"
        ref="filterHandler"
        @setFilters="handleSetFilters"
        @filtersReady="onFiltersReady"
      />
      <FilterDialog
      ref="filterDialog"
      v-else
      @setFilters="handleSetFilters"
      @filtersReady="onFiltersReady" />
      <div class="filterButtonWrapper">
        <v-select
          dense
          outlined
          hide-details
          v-if="!isMobileAndStandalone"
          v-model="exportFormat"
          class="export-dropown"
          :items="exportOptions"
          :label="getTranslation('exportDropdownLabel')"
        />

<!-- EXPORT -->
<v-btn @click="handleExportAction" v-if="!isMobileAndStandalone" icon height="40" width="40" :disabled="loading">
    <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"
              >mdi-export-variant</v-icon
            >
          </template>
        <span>{{ getTranslation('export') }}</span>
        </v-tooltip>
</v-btn>

<!-- RELOAD -->
<v-btn @click="reload()" icon height="40" width="40" :disabled="loading">
    <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"
              >mdi-reload</v-icon
            >
          </template>
        <span>{{ getTranslation('reload') }}</span>
        </v-tooltip>
</v-btn>

<!-- RESET FILTERS-->
<v-btn @click="handleResetFilters" icon height="40" width="40">
  <v-tooltip bottom >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on"
                >mdi-filter</v-icon
              >
        </template>
        <span>{{ getTranslation('resetFilters') }}</span>
        </v-tooltip>
</v-btn>
      </div>
    </div>
    <v-row>
      <v-col>
        <v-card outlined>
          <!-- TABLE -->
          <!-- COLUMN FILTER -->
          <TableColumnsSelect :headers="columnsData" v-if="showDropdownMenu" />
          <v-data-table
            :class="[
              'elevation-0', 'multiColumnsTable',
              { 'seven-table-style': isSeven },
              {'row-pointer': shouldShowPointerOnRow }]"
            :headers="visibleHeaders"
            :page="pagination.page"
            :items-per-page.sync="pagination.size"
            :server-items-length="pagination.total"
            :options.sync="options"
            :footer-props="combineFooterProps"
            :items="items"
            :loading="loading"
            @update:options="onOptionsUpdate($event)"
            @click:row="onClickOnRow"
          >
            <!-- STATUS AS CHIP  -->
            <template v-slot:[`item.status`]="{ item }">
              <v-chip class="status" :color="getColor(item.statusKey)" small outlined label>{{
                item.status
              }}</v-chip>
            </template>
            <!-- COLUMN SELECT BUTTON -->
            <template slot="header.selectIcon">
              <v-btn icon @click="toggleDropdownMenu()" class="disabled--text visibleOnOverlay">
                <v-icon :color="selectHeadersColor">mdi-filter-variant</v-icon>
              </v-btn>
            </template>
            <!-- TOTALS ROW -->
            <template slot="body.prepend" v-if="shouldShowTotalsMobile">
              <table class="totalsRowMobile">
                <tr>
                  <td>
                    <LocalizedLabel>total</LocalizedLabel>
                  </td>
                </tr>
                <tr v-for="(total, index) in transformedTotals" :key="index" v-show="total.value">
                  <td>{{ total.text }}</td>
                  <td class="formatValue">{{ total.value }}</td>
                </tr>
              </table>
              <v-divider></v-divider>
            </template>
            <!-- TOTALS ROW DESKTOP -->
            <template slot="body.append" v-if="shouldShowTotals">
              <tr class="totalsRow">
                <td>
                  <LocalizedLabel>total</LocalizedLabel>
                </td>
                <td v-for="(total, index) in transformedTotals" :key="index">
                  <div v-if="!loading">{{ total.value }}</div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-overlay :value="showDropdownMenu" @click.native="showDropdownMenu = false" opacity="0">
          </v-overlay>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  update,
  toNumber,
  assign,
  set,
  clone,
  dropRight,
  drop,
  isNull,
  isEmpty,
  merge,
  head,
  isArray,
  defaultTo,
} from 'lodash';
import { pipe, tap } from 'lodash/fp';
import {
  getStatusColor,
  selectedColumnsFilter,
  formatQueryParams,
  composeAsync,
  asyncTap,
  prepareForQueryParamsReplace,
} from '@/utility';
import { getColumnsData } from '@/utility/columnsController';

import TableColumnsSelect from '@/components/TableColumnsSelect';
import FilterHandler from '@/components/Filters/FilterHandler';
import FilterDialog from '@/components/Filters/FilterDialog';
import { exportFormatTypes } from '@/utility/types';

export default {
  name: 'tableFilterGrid',
  components: {
    TableColumnsSelect,
    FilterHandler,
    FilterDialog,
  },
  props: {
    listName: {
      type: String,
      default: '',
    },
    /**
     * Run immediatelly after fetching data
     *
     * Needed as the vuetify components require a model different from
     * the one we receive
     */
    transformList: {
      type: Function,
      default: () => {},
    },
    /**
     * Maps all the totals to a preffered format
     * (e.g. numbers of decimals)
     */
    transformTotal: {
      type: Function,
      default: () => {},
    },
    onClickOnRow: {
      type: Function,
      default: () => {},
    },
    headers: {
      type: Array,
    },
  },
  data() {
    return {
      exportFormat: { value: 'JSON', text: 'JSON' },
      totals: {},
      filtersReady: false,
      initialQueryMade: false,
      pagination: {
        // * Must initialize server-items-length with 0,
        // * Otherwise, the initial options.page property
        // * Will be overriden to 1 by VData
        // * (see updateOptions method)
        // * https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VData/VData.ts
        total: 0,
      },
      options: {
        itemsPerPage: toNumber(this.$route.query.itemsPerPage) || 10,
        page: toNumber(this.$route.query.page) || 1,
        sortBy: [isArray(this.$route.query.sortBy) ? head(this.$route.query.sortBy) : this.$route.query.sortBy],
        sortDesc: [this.$route.query.sortDesc === true],
      },
      items: [],
      showDropdownMenu: false,
    };
  },
  computed: {
    ...mapGetters([
      'auth',
      'config',
      'isSeven',
      'loading',
      'isMobile',
      'dateRange',
      'allFilters',
      'currentPage',
      'footerProps',
      'platformName',
      'getTranslation',
      'allFiltersPerTenant',
      'hasFiltersReqFailed',
      'isMobileAndStandalone',
      'selectedFiltersPerTenant',
    ]),
    showResetFiltersBtn() {
      return this.currentPage !== 'events';
    },
    shouldShowPointerOnRow() {
      return this.listName === 'tickets';
    },
    selectHeadersColor() {
      return this.isSeven ? 'white' : '';
    },
    shouldShowTotalsMobile() {
      return this.items?.length && this.isMobileAndStandalone && this.totals;
    },
    shouldShowTotals() {
      return this.items?.length && !this.isMobileAndStandalone && this.totals;
    },
    exportOptions() {
      return Object.keys(exportFormatTypes).map((key) => ({
        text: key,
        value: key,
      }));
    },
    pageCount() {
      return this.getPageCount(this.pagination);
    },
    combineFooterProps() {
      return assign({ disablePagination: this.loading }, this.footerProps);
    },
    isFirstPage() {
      return this.options.page === 1;
    },
    visibleHeaders() {
      // Desktop view has a special header on the far right
      // that lets users select which columns they want to
      // see in the tabular view.
      const mappedHeadersForLS = this.getStateAndLSHeadersDifference();
      const translatedHeaders = this.translateHeaders(this.headers);

      const sievedStateFilters = this.headers.map((header) => {
        const correspondinHeaderObj = mappedHeadersForLS.find(({ value }) => header.value === value);
        return correspondinHeaderObj ? { ...header, visible: correspondinHeaderObj.visible }
          : { ...header, visible: false };
      });

      const visibleHeadersForDesktopView = selectedColumnsFilter(translatedHeaders, sievedStateFilters);

      // The forementioned special header is not needed
      // in the mobile view (if not on seven).
      return this.isMobileAndStandalone ? dropRight(visibleHeadersForDesktopView) : visibleHeadersForDesktopView;
    },
    columnsData() {
      const headerDiff = this.getStateAndLSHeadersDifference();
      return this.headers.map((header) => {
        const correspondingHeaderObj = headerDiff.find(({ value }) => header.value === value);
        return { ...header, visible: correspondingHeaderObj.visible || header.visible };
      });
    },
    /**
     * The non existing totals have the total.value property equal to null,
     * This function makes sure no transformations are done to those totals.
     */
    transformedTotals() {
      return this.getTotals().map((total) => {
        const value = isNull(total.value) ? null : this.transformTotal(total.value);
        return { ...total, value };
      });
    },
  },
  methods: {
    ...mapActions([
      'getData',
      'setLoading',
      'resetFilters',
      'setDownloadFile',
      'postData',
      'setSortableFields',
      'setSort',
      'setHeaders',
      'clearSelectedFiltersPerTenant',
    ]),
    handleResetFilters() {
      this.resetFilters();
      this.handleSetFilters();
    },
    getColumnsForExport() {
      return `columnsForExport=${getColumnsData(this.$route.name).filter(({ visible }) => visible)
        .map(({ text }) => text.replace('payin', 'payment'))
        .filter((text) => text)
        .join(',')}`;
    },
    getCombinedFilters() {
      const excessPropsFromVuetify = ['groupBy', 'groupDesc', 'multiSort', 'mustSort'];
      const cleanOptions = Object.entries(this.options).filter(([key]) => !excessPropsFromVuetify.includes(key))
        .reduce((acc, [key, value]) => {
        // Array.reduce is internally pure, so no need to worry
        // about inducing side effects outside of scope
        /* eslint-disable no-param-reassign */
          acc[key] = value;
          return acc;
        }, {});

      return merge(cleanOptions, this.allFilters);
    },
    async handleExportAction() {
      const response = await this.getExportedData();
      this.extractAndStoreFileData(response);
    },
    extractAndStoreFileData(response) {
      const fileName = response.headers['content-disposition'].split('=')[1];
      const href = window.URL.createObjectURL(response.data);

      this.setDownloadFile({
        href,
        fileName,
        download: true,
      });
    },
    getExportedData() {
      return this.postData({
        route: `${this.listName}/action/export?${formatQueryParams({
          dateFrom: this.dateRange.dateFrom,
          dateTo: this.dateRange.dateTo,
          locale: this.config.locale,
          timezone: true,
          ...this.getCombinedFilters(),
        })}&${this.getColumnsForExport()}`,
        data: {},
        config: {
          responseType: 'blob',
          timeout: 13000,
          headers: {
            'Content-Type': exportFormatTypes[this.exportFormat],
          },
        },
      });
    },
    onFiltersReady() {
      this.markFiltersAsReady();
      if (!this.initialQueryMade) { this.handleOptionsAndFiltersChange(this.getCombinedFilters()); }
    },
    markFiltersAsReady() {
      this.filtersReady = true;
    },
    async onOptionsUpdate(options) {
      set(this, 'options', options);
      // This check exists because the update:options is triggered immediatelly as the app starts,
      // (possible vuetify behaviour), i.e. way before we've fetched all the available filters
      // and selected local ones from query params, which is unconvenient. By checking for products,
      // we're making sure that we've initialised the filters, as a tenant cannot exist without
      //  at least one active product
      if (!this.filtersReady) return;

      set(this, 'initialQueryMade', true);
      this.handleOptionsAndFiltersChange(this.getCombinedFilters());
    },
    async handleSetFilters() {
      if (this.isFirstPage) {
        // If on the first page, no need to change the pagination
        this.handleOptionsAndFiltersChange(this.getCombinedFilters());
      } else {
        // If not on the first page, reset page to 1, the update handler will do the rest
        set(this.pagination, 'total', 0);
        set(this.options, 'page', 1);
      }
    },
    async triggerGetAndStoreInitialFilters() {
      await this.$refs.filterHandler.getAndStoreInitialFilters();
      if (this.isMobile) {
        await this.$refs.filterDialog.getAndStoreInitialFilters();
      }
    },
    async reload() {
      if (this.hasFiltersReqFailed) {
        this.triggerGetAndStoreInitialFilters();
      }

      this.handleOptionsAndFiltersChange(this.getCombinedFilters());
    },
    refreshQueryParams(newParams) {
      this.$router.replace(
        {
          query: { ...prepareForQueryParamsReplace(newParams), locale: this.config.locale },
        },
        () => {},
      );
    },
    /**
     * Transforms the response to a format
     * that fits Vuetify's internal data structures
     */
    switchPaginationIndexing(response) {
      const { pagination, ...other } = response;
      pagination.page += 1;

      return Promise.resolve({ pagination, ...other });
    },
    async query(params) {
      return this.getData({ route: `/${this.listName}?${params}` });
    },
    /**
     * Only look for the (possibly) existing totals to the currently visible headers
     */
    getTotals() {
      return drop(
        this.visibleHeaders.map((header) => {
          const { totalKey } = header;
          const totalExistsForHeader = isEmpty(this.totals) ? false : totalKey in this.totals;
          const total = totalExistsForHeader ? defaultTo(this.totals[totalKey], 0) : null;
          return {
            text: header.text,
            value: total,
          };
        }),
      );
    },
    /**
     * @param {Promise<{data: Object, pagination:Object}>} result
     * Updates the list view.
     */
    async updateView(result) {
      set(this, 'items', result.data[this.listName]);
      set(this, 'pagination', result.pagination);
    },
    /**
     * Combines query params, fetches and transforms the data
     * to a view appropriate format
     *
     * Perform a couple of side effects, such as
     * updating the url bar with the current query params
     * and saving the totals in the component
     */
    handleOptionsAndFiltersChange(options) {
      return pipe(
        this.getQueryParamsObject,
        tap(this.refreshQueryParams),
        formatQueryParams,
        composeAsync(
          this.query,
          asyncTap(({ ticketTotals }) => {
            this.totals = ticketTotals;
          }),
          this.switchPaginationIndexing,
          this.getResponseWithValidPagination,
          this.getTransformedListResponse,
          asyncTap(({ sortableFields }) => this.setSortableFields(sortableFields)),
          this.updateView,
        ),
      )(options);
    },
    translateHeaders(headers) {
      return headers.map((header) => ({ ...header, text: this.getTranslation(header.text) }));
    },
    toggleDropdownMenu() {
      this.showDropdownMenu = !this.showDropdownMenu;
    },

    getColor(status) {
      return getStatusColor(status);
    },
    getPageCount(pagination) {
      return Math.ceil(pagination?.total / pagination?.size) || 1;
    },
    getQueryParamsObject(options) {
      return assign(clone(options), {
        dateFrom: this.dateRange.dateFrom,
        dateTo: this.dateRange.dateTo,
      });
    },
    /**
     * Returns the response with transformed list
     */
    async getTransformedListResponse(response) {
      const list = await this.transformList(response.data);
      const path = `data.${this.listName}`;

      return assign(update(clone(response), path, () => list));
    },
    /**
     * Removes pagination inconsistencies
     */
    getResponseWithValidPagination(response) {
      const { pagination } = response;
      const pageCount = this.getPageCount(pagination);
      // Reset to max page
      if (pageCount < pagination?.page) {
        pagination.page = pageCount;
      }

      return Promise.resolve(assign(response, { pagination }));
    },
    /**
     * Compares state headers and the ones saved to ls.
     * I a header exists both in ls and in state, we're taking the value of the visible property
     * from the obj in ls. If it exits in ls and not in state, it is ignored.
     * If it exists only in the state, it is set to local storage with false for the visible property's value.
     */
    getStateAndLSHeadersDifference() {
      const columnsData = getColumnsData(this.$route.name);

      if (columnsData) {
        return this.headers.map(({ visible, value, text }) => ({ visible, value, text })).map(({
          value,
          text,
        }) => {
          const lsHeader = columnsData.find((headerObj) => headerObj.value === value);
          return { value, visible: lsHeader ? lsHeader.visible : false, text };
        });
      }
      return this.headers.map(({ visible, value }) => ({ visible, value }));
    },
    initHeadersVisibility() {
      const mappedHeadersForLS = this.getStateAndLSHeadersDifference();
      localStorage.setItem(this.$route.name, JSON.stringify(mappedHeadersForLS));
    },
  },
  created() {
    this.initHeadersVisibility();
  },
};
</script>

<style lang="scss" scoped>
.export-dropdown{
  width: fit-content;
}
.seven-table-style {
  ::v-deep {
    .v-data-table-header {
      th {
        color: white !important;
      }
      background-color: var(--v-primary-base);
    }
  .v-data-table-header__icon {
    color: white!important;;
    }
  }
}

::v-deep {
  .row-pointer{
    tbody {
    tr {
      cursor: pointer;
    }
  }
  }
  .v-pagination__navigation,
  .v-pagination__item {
    box-shadow: none;
  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: none;
  }
}

.max-width-80 {
  max-width: 80px;
}
</style>
