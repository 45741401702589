import Vue from 'vue';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import Keycloak from 'keycloak-js';
import dark from '@/plugins/vuetify/themes/dark';
import light from '@/plugins/vuetify/themes/light';
import { ConfigurationService } from '@nsftx/games-config';
import { BusService } from '@nsftx/games-bus';
import { defaultTo } from 'lodash';
import LocalizedLabel from './components/LocalizedLabel';
import i18n from './i18n';
import vuetify from './plugins/vuetify/vuetify';

Vue.use(LocalizedLabel);

const queryParams = new URLSearchParams(window.location.search);
const platformName = defaultTo(queryParams.get('platform'), 'standalone');
const locale = defaultTo(queryParams.get('locale'), 'en');
const slaveId = queryParams.get('slaveId');

// Set themes based on platform
vuetify.framework.theme.themes.dark = dark[platformName];
vuetify.framework.theme.themes.light = light[platformName];
const isDarkTheme = localStorage.getItem('isDarkTheme');
// Seven integration will always be light theme
vuetify.framework.theme.dark = isDarkTheme === 'true' && platformName === 'standalone';

const getTranslations = async (localeString) => {
  const { default: translations } = await i18n.getTranslations(localeString);

  return translations;
};

const setup = {};

setup.standalone = async () => {
  const config = {
    platformName,
    locale,
  };

  store.dispatch('setConfig', config);

  const translations = await getTranslations(config.locale);
  store.state.translations = translations;

  const { keycloakOptions, keycloakConfig } = store.state;
  const keycloak = new Keycloak(keycloakConfig);

  keycloak.init(keycloakOptions).then((auth) => {
    store.state.keycloak = keycloak;
    store.dispatch('setAuth', { token: keycloak.token, refreshToken: keycloak.refreshToken });

    if (!auth) {
      window.location.reload();
    } else {
      new Vue({
        vuetify,
        router,
        store,
        render: (h) => h(App),
      }).$mount('#app');
      store.dispatch('keycloakRefreshToken');
    }
  });
};

setup.seven = async () => {
  const productName = slaveId.replace('Plugin', '');
  const channel = 'RETAIL';
  const applicationName = 'admin';

  const configService = new ConfigurationService({
    environment: process.env.VUE_APP_ENVIRONMENT,
    applicationName,
    channel,
    productName,
    clientAdapters: [
      {
        name: 'GravityGatewayAdapter',
        options: {
          slaveId,
        },
      },
    ],
  });
  Vue.prototype.$config = configService;

  const config = await configService.getConfiguration();

  store.dispatch('setConfig', config);
  store.dispatch('setAuth', { token: config.user.token, refreshToken: config.user.refreshToken });

  const translations = await getTranslations(config.locale);
  store.state.translations = translations;

  const busService = new BusService({
    environment: config.environment,
    platform: config.platform,
    platformName: config.platformName,
    productName: config.productName,
    productId: config.productId,
    tenantId: config.tenantId,
    locale: config.locale,
    applicationName: config.applicationName,
    clientMessageProtocol: config.messageProtocol,
    adapters: [],
    clientAdapters: [
      {
        name: 'GravityGatewayAdapter',
        options: {
          slaveId,
        },
      },
    ],
  });

  Vue.prototype.$busService = busService;

  busService.start();

  new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
};

setup[platformName]();
