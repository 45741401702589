<template>
  <div class="tabs">
    <v-tabs dark background-color="darkGrey" slider-color="primary" height="43">
      <v-tab v-for="item in navigation" :key="item.title" @click="getNavigationPath(item.link)">
        {{ getTranslation(item.title) }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'sevenTabs',
  methods: {
    ...mapActions(['setCurrentPage']),
    getNavigationPath(value) {
      if (this.$route.path !== value) {
        this.$router.push(value);
      }
      this.setCurrentPage(value.replace('/', ''));
    },
  },
  computed: {
    ...mapGetters(['navigation', 'getTranslation']),
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  ::v-deep .v-tabs-slider-wrapper {
    top: 0;
  }

  ::v-deep .v-tabs-slider {
    height: 3px;
  }
}
</style>
