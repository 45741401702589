<template>
  <div :class="{ 'pa-4': isSeven }">
    <TableFilterGrid v-bind="tableAndFiltersProps" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TableFilterGrid from '@/components/TableFilterGrid';
import listTypes from '@/utility/types/listTypes';
import {
  nilSentry, isoToDateTimeSeconds, isoToTimeSeconds, roundDecimals,
} from '@/utility';
import { partial } from 'lodash';

export default {
  name: 'EventsGrid',
  components: {
    TableFilterGrid,
  },
  computed: {
    ...mapGetters(['isSeven', 'sortableFields', 'currentPageHeaders', 'locale']),
    headersWithSortableFields() {
      return this.currentPageHeaders.map((header) => (
        { ...header,
          sortable: this.sortableFields.includes(header.value) }));
    },
    tableAndFiltersProps() {
      return {
        listName: listTypes.EVENTS,
        transformList: this.transformList,
        transformTotal: this.transformTotal,
        onClickRow: () => { },
        headers: this.headersWithSortableFields,
      };
    },
  },
  methods: {
    ...mapActions(['setCurrentPage']),
    formatEventForView(formatters, event) {
      const { dateFormatter, timeFormatter } = formatters;
      return {
        ...event,
        multiplierStartedAt: nilSentry(event.multiplierStartedAt, timeFormatter),
        startedAt: nilSentry(event.startedAt, dateFormatter),
        endedAt: nilSentry(event.endedAt, dateFormatter),
        luckyMultiplier: event.luckyMultiplier !== '0' ? nilSentry(event.luckyMultiplier, this.localiseAndRoundDecimals) : '-',
        multiplier: nilSentry(event.multiplier, this.localiseAndRoundDecimals),
      };
    },
    localiseAndRoundDecimals(num) {
      return roundDecimals(num, this.locale);
    },
    transformTotal(value) {
      return value;
    },
    async transformList(data) {
      const dateFormatter = await isoToDateTimeSeconds(this.locale);
      const timeFormatter = await isoToTimeSeconds(this.locale);

      const formatters = {
        dateFormatter,
        timeFormatter,
      };

      const events = data?.map(partial(this.formatEventForView, formatters));

      return events;
    },
  },
  created() {
    this.setCurrentPage('events');
  },
};
</script>

<style lang="scss" scoped></style>
