<template>
  <div class="info-message mx-4" v-if="info">
    <v-alert
      width="300"
      :type="info.type"
      v-model="alert"
      @input="close"
      data-test="alertMessage"
      dismissible
    >
      {{ info.message | translate }}
    </v-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'InfoMessage',
  data() {
    return {
      alert: true,
      alertTimeout: null,
    };
  },
  computed: {
    ...mapGetters(['info']),
  },
  methods: {
    ...mapActions(['setInfo']),
    close() {
      this.resetAlert();
      if (this.alertTimeout !== null) {
        clearTimeout(this.alertTimeout);
        this.alertTimeout = null;
      }
    },
    resetAlert() {
      this.alert = false;
      this.setInfo(null);
    },
  },
  mounted() {
    if (this.alertTimeout === null) {
      this.alertTimeout = setTimeout(() => {
        this.resetAlert();
      }, 3000);
    }
  },
};
</script>

<style lang="scss" scoped>
.info-message {
  position: fixed;
  bottom: 0;
}
</style>
