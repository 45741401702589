import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);
const opts = {
  theme: {
    options: {
      customProperties: true,
    },
  },
};
export default new Vuetify(opts);
