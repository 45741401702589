<template>
  <div><SevenTabs /> <router-view /></div>
</template>

<script>
import SevenTabs from '@/components/SevenTabs';

export default {
  name: 'seven',
  components: {
    SevenTabs,
  },
};
</script>

<style lang="scss" scoped></style>
