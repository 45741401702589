import { has, clone, forEach, merge } from 'lodash';
import { endOfDay, formatISO, startOfDay } from 'date-fns';
import {
  splitAfterFirst,
  formatQueryParams,
  filtersMapper,
  fromParamsObjToStateObj,
} from '@/utility';
import isMobile from 'ismobilejs';
import httpTypes from '@/utility/types/httpTypes';
import { dataApi } from '../api';
import types from './mutationTypes';

const TIMEOUT_STRING = 'ECONNABORTED';
export default {
  setLoading({ commit }, payload) {
    commit(types.SET_LOADING, payload);
  },
  setConfig({ commit }, config) {
    commit(types.SET_CONFIG, config);
  },
  setAuth({ commit }, auth) {
    commit(types.SET_AUTH, auth);
  },
  setDetailsModalConfig({ commit }, payload) {
    commit(types.SET_DETAILS_MODAL_CONFIG, payload);
  },
  signOut({ state }) {
    state.keycloak.logout().then(() => {
      state.keycloak = null;
    });
  },
  resetFilters({ getters: { isSeven }, dispatch }) {
    if (isSeven) { dispatch('clearSelectedFiltersPerTenant'); } else {
      dispatch('clearSelectedFiltersPerTenant');
    }
  },
  toggleDetailsModal({ commit }, payload) {
    commit(types.TOGGLE_DETAILS_MODAL, payload);
  },
  setSelectedTicket({ commit }, payload) {
    commit(types.SET_SELECTED_TICKET, payload);
  },
  async handler({ commit, dispatch, getters }, data) {
    try {
      const response = await dataApi[data.type](data.payload);
      if (data.payload.mutationType && response.data) {
        commit(data.payload.mutationType, response.data);
      }
      dispatch('setLoading', false);
      return response;
    } catch (error) {
      if (error.code === TIMEOUT_STRING) {
        dispatch('setLoading', false);
        dispatch('setInfo', {
          type: 'error',
          message: getters.getTranslation('requestTimeout'),
        });
      }
      // TODO handle error
      return error;
    }
  },
  setInfo({ commit }, payload) {
    commit(types.SET_INFO, payload);
  },
  async getData({ dispatch }, payload) {
    if (!has(payload, 'loading')) {
      dispatch('setLoading', true);
    }
    const response = await dispatch('handler', { payload, type: httpTypes.GET_DATA });
    return response;
  },
  async postData({ dispatch }, payload) {
    return dispatch('handler', { payload, type: httpTypes.POST_DATA });
  },
  setMobileDetection({ commit }) {
    commit(types.SET_MOBILE_DETECTION, isMobile().any);
  },
  setDownloadFile({ commit }, payload) {
    commit(types.SET_DOWNLOAD_FILE, payload);
  },
  setDateRange({ commit }, dateRange) {
    commit(types.SET_DATE_RANGE, dateRange);
  },
  setCurrentPage({ commit }, pageName) {
    commit(types.SET_CURRENT_PAGE, pageName);
  },
  setAllFiltersPerTenant({ commit }, payload) {
    commit(types.SET_ALL_FILTERS_PER_TENANT, payload);
  },
  setSelectedFiltersPerTenantValues({ commit }, payload) {
    commit(types.SET_SELECTED_FILTERS_PER_TENANT_VALUES, payload);
  },
  setThemeValue({ commit }, payload) {
    commit(types.SET_THEME_VALUE, payload);
  },
  setSortableFields({ commit }, payload) {
    commit(types.SET_SORTABLE_FIELDS, payload);
  },
  setHeaders({ commit }, payload) {
    commit(types.SET_HEADERS, payload);
  },
  initLocalStateOfFiltersFromQueryParams({ dispatch, getters }, payload) {
    const { query } = payload;
    // Init selections from the query params
    const stateModel = fromParamsObjToStateObj(query, getters.allFiltersPerTenant);

    forEach(stateModel, (filterValues, filterName) => {
      dispatch('setSelectedFiltersPerTenantValues', { [filterName]: filterValues });
    });
  },
  collectAllFilters({ getters }) {
    return merge(clone(getters.selectedFiltersPerTenant), getters.dateRange);
  },
  async initFilters({ dispatch, getters }, payload) {
    dispatch('clearAllFilters');
    dispatch('setInitialDateRange', { dateFrom: payload.dateFrom, dateTo: payload.dateTo });
    if (getters.isSeven) {
      dispatch('initFiltersFromGG');
    } else {
      dispatch('initTenantIdsFromKeycloak');
    }
    await dispatch('getAndStoreInitialFilters', payload.route);
    await dispatch('initLocalStateOfFiltersFromQueryParams', payload.route);
  },
  async getAndTransformFilters({ dispatch }, payload) {
    return filtersMapper(await dispatch('getData', payload));
  },
  setExpandedFiltersPerTenant({ dispatch }, payload) {
    const filtersClone = clone(payload);
    filtersClone.search = '';

    dispatch('setAllFiltersPerTenant', filtersClone);
  },
  async getAndStoreInitialFilters({ dispatch }, route) {
    const filters = await dispatch('getInitialFilters', route);

    dispatch('setExpandedFiltersPerTenant', filters);
  },
  async getInitialFilters({ getters, dispatch }, route) {
    const products = getters.getSelectedFilterPerTenant('products');
    if (getters.isSeven) {
      return dispatch('getAndTransformFilters', {
        route: `filters?${formatQueryParams({
          tenants: [{ value: getters.config.tenantId }],
          dateFrom: getters.dateRange.dateFrom,
          dateTo: getters.dateRange.dateTo,
          products,
        })}`,
      });
    }
    const tenants = (await dispatch('getTenantIdsFromKeycloak'))
      .map((id) => ({ value: id }));

    return dispatch('getAndTransformFilters', {
      route: `filters?${formatQueryParams({
        ...route.query,
        sortBy: '',
        sortDesc: '',
        products,
        tenants,
      })}`,
    });
  },
  getTenantIdsFromKeycloak({ getters }) {
    return getters.keycloak.idTokenParsed.tenants
      .filter((tenantString) => tenantString.indexOf('GamesTenants'))
      .map((tenantString) => splitAfterFirst(tenantString, '_'));
  },
  async initTenantIdsFromKeycloak({ dispatch }) {
    const tenants = await dispatch('getTenantIdsFromKeycloak');
    dispatch('setSelectedFiltersPerTenantValues', {
      tenants,
    });
  },
  initFiltersFromGG({ dispatch, getters }) {
    const { tenantId, productName } = getters.config;

    dispatch('setSelectedFiltersPerTenantValues', ({
      products: [productName],
      tenants: [tenantId],
    }));
  },
  setInitialDateRange({ dispatch, getters }, payload) {
    let dateFrom;
    let dateTo;

    if (getters.isSeven) {
      const now = new Date();
      dateFrom = formatISO(startOfDay(now));
      dateTo = formatISO(endOfDay(now));
    } else {
      const dateFromParam = payload.dateFrom;
      const dateToParam = payload.dateTo;

      if (dateFromParam && dateToParam) {
        dateFrom = formatISO(startOfDay(new Date(dateFromParam)));
        dateTo = formatISO(new Date(dateToParam));
      }
    }

    dispatch('setDateRange', ({ dateFrom, dateTo }));
  },
  // TODO - rewrite this function to take in filters' names
  //* Currently, this clears all filter input, except the tenant
  clearSelectedFiltersPerTenant({ commit }) {
    commit(types.CLEAR_SELECTED_FILTERS_PER_TENANT);
  },
  clearAllFilters({ commit }) {
    commit(types.CLEAR_ALL_FILTERS);
  },
  keycloakRefreshToken({ state, dispatch }) {
    const lifespan = Math.round(
      state.keycloak.tokenParsed.exp + state.keycloak.timeSkew - new Date().getTime() / 1000,
    ) * 1000;
    setInterval(() => {
      state.keycloak
        .updateToken(lifespan)
        .then(() => {})
        .catch(() => {
          dispatch.signOut();
        });
    }, lifespan);
  },
};
